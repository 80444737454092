
import { handleErrorUI, checkFieldErrors, handleSuccessUI } from '@/util/error';
import { validateEmail } from '@/util/validation';
import AuthMixin from '@/mixins/AuthMixin';
import LoginTabs from '@/components/Auth/Tabs.vue';
import { mapGetters, mapMutations } from 'vuex';
import { Notify } from 'quasar';

const createErrors = () => ({
  email: ''
});

export default {
  components: { LoginTabs },
  mixins: [AuthMixin],
  data() {
    return {
      email: '',
      errors: createErrors(),
      resetSent: false,
      i18n: {},
      messageTemplate:
        'Reset password link sent to your email. <b>Please check your inbox.</b>'
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'getLocale'])
  },
  methods: {
    ...mapMutations('user', ['setLocale']),
    setupLocale(locale) {
      this.setLocale({ locale });
    }, // setupLocale
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
      );
    },
    async sendReset() {
      this.errors = createErrors();
      if (!this.email) {
        this.errors.email = this.$t('AuthPage.field_is_required');
      } else if (!validateEmail(this.email)) {
        this.errors.email = 'Email is invalid';
      }
      if (!checkFieldErrors(this.errors)) {
        return;
      }
      try {
        /* const { data } = await this.$repo.auth.getCustomerByEmail(this.email);
        const userIsDisabled = await this.$repo.auth.isUserDisabled(
          this.email,
          data.tenantName
        );
        if (userIsDisabled) {
          await this.$q.notify({
            icon: 'fas fa-exclamation-triangle',
            type: 'negative',
            position: 'top',
            message: this.$t('AuthPage.reset_password_disabled_user_alert'),
            timeout: 3000
          });
        } else { */
        const response = await this.$repo.auth.forgotPassword(this.email);
        //  console.log(response);
        this.resetSent = true;
        await this.$q.notify({
          icon: 'fas fa-exclamation-triangle',
          type: 'positive',
          position: 'top',
          message: this.$t('AuthPage.user_email_sent'),
          timeout: 3000
        });
        await this.$router
          .push({
            name: 'auth:signup:request',
            query: { email: this.email, screen: 'forgot' }
          })
          .catch(_ => {});
      } catch (err) {
        await this.$q.notify({
          icon: 'fas fa-exclamation-triangle',
          type: 'negative',
          position: 'top',
          message: this.$t('AuthPage.email_does_not_exist'),
          timeout: 3000
        });
      }
    }
  }
};
